#content
  padding-top: calc(#{$navbar-height} + 1rem)

  &.content
    &--with-sidebar
      @include media-breakpoint-up(md)
        margin-left: $nav-sidebar-width
    &--with-floating_sidebar
      padding-top: 1rem
      @include media-breakpoint-up(md)
        margin-left: calc(#{$nav-sidebar-width} + 3rem)